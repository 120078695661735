import styles from './Checkbox.module.css';

const Checkbox = ({
  required,
  register,
  errors,
  children,
  name,
  light,
  className,
}) => {
  return (
    <label
      className={`${styles.label} ${className} ${
        className ? styles.label_hidden : ''
      }`}
    >
      <input
        type='checkbox'
        {...register(name, {
          required: required,
        })}
        className={styles.input}
      />
      <div
        className={`${styles.checkbox__wrapper} ${
          light ? styles.checkbox__wrapper_light : ''
        } ${errors && errors[name] ? styles.checkbox__error : ''}`}
      >
        <svg
          width='12'
          height='11'
          viewBox='0 0 12 11'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M11.9751 1.78164C11.9751 1.51745 11.8705 1.264 11.6843 1.07663C11.5874 0.986724 11.4737 0.916908 11.3497 0.87122C11.2257 0.825533 11.0938 0.804882 10.9618 0.810462C10.8297 0.816041 10.7001 0.84774 10.5804 0.903726C10.4607 0.959712 10.3532 1.03887 10.2643 1.13664L3.53428 8.29663L1.77429 6.42664C1.68735 6.32027 1.57944 6.23296 1.45728 6.17015C1.33511 6.10733 1.20131 6.07036 1.06423 6.06154C0.927145 6.05271 0.789713 6.07223 0.660503 6.11886C0.531294 6.1655 0.413078 6.23826 0.313229 6.3326C0.213379 6.42694 0.134039 6.54084 0.0801524 6.6672C0.0262663 6.79355 -0.0010088 6.92966 2.85145e-05 7.06702C0.00106583 7.20439 0.0303933 7.34006 0.0861815 7.46559C0.14197 7.59112 0.223023 7.70381 0.324285 7.79663L2.76428 10.4266C2.85839 10.5254 2.9717 10.6038 3.09724 10.6571C3.22278 10.7104 3.3579 10.7375 3.49428 10.7366C3.63067 10.7375 3.76579 10.7104 3.89133 10.6571C4.01687 10.6038 4.13018 10.5254 4.22429 10.4266L11.6843 2.48663C11.8705 2.29927 11.9751 2.04582 11.9751 1.78164Z'
            fill={`${light ? '#fff' : '#13AD37'}`}
          />
        </svg>
      </div>
      <span
        className={`${styles.checkbox__text} ${
          light ? styles.checkbox__text_light : ''
        }`}
      >
        {children}
      </span>
    </label>
  );
};

export { Checkbox };
