import React, {DetailedHTMLProps, HTMLAttributes, ReactNode} from 'react';
import styles from './Hr.module.scss'
import cn from "classnames";

interface HrProps extends DetailedHTMLProps<HTMLAttributes<HTMLHRElement>, HTMLHRElement> {

}
const Hr = ({className, ...props}: HrProps) => {
    return (
        <hr className={cn(styles.hr, className)} {...props}/>
    );
};

export default Hr;