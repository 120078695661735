import { useEffect, useState } from 'react'

const useWindowWidth = () => {
  let stateVariant = undefined
  const [windowWidth, setWindowWidth] = useState(stateVariant)

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth)
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}

export { useWindowWidth }
