const CloseIcon = ({ onClick, className = '' }) => {
  return (
    <svg
      className={`close-icon ${className}`}
      onClick={onClick}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5303 2.53033L15.0607 2L14 0.93934L13.4697 1.46967L14.5303 2.53033ZM1.46967 13.4697L0.93934 14L2 15.0607L2.53033 14.5303L1.46967 13.4697ZM2.53033 1.46967L2 0.93934L0.93934 2L1.46967 2.53033L2.53033 1.46967ZM13.4697 14.5303L14 15.0607L15.0607 14L14.5303 13.4697L13.4697 14.5303ZM13.4697 1.46967L7.46967 7.46967L8.53033 8.53033L14.5303 2.53033L13.4697 1.46967ZM7.46967 7.46967L1.46967 13.4697L2.53033 14.5303L8.53033 8.53033L7.46967 7.46967ZM1.46967 2.53033L7.46967 8.53033L8.53033 7.46967L2.53033 1.46967L1.46967 2.53033ZM7.46967 8.53033L13.4697 14.5303L14.5303 13.4697L8.53033 7.46967L7.46967 8.53033Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloseIcon
