import axios from "axios";

const AUTH_USER = process.env.NEXT_PUBLIC_USER;
const AUTH_PASSWORD = process.env.NEXT_PUBLIC_PASSWORD;

const BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_API_URL;
const PROXY_BASE_API_URL = process.env.NEXT_PUBLIC_FRONT_PROXY_API_URL;
const PROXY_FORM_API_URL = process.env.NEXT_PUBLIC_FORM_PROXY_API_URL;
const VERCEL_URL = process.env.NEXT_PUBLIC_VERCEL_URL;

const getAuth = () => {
    if (AUTH_USER && AUTH_PASSWORD) {
        return {
            auth: {
                username: AUTH_USER,
                password: AUTH_PASSWORD
            }
        };
    } else {
        return {};
    }
};

const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
};


export const axiosServer = axios.create({
    params: {
      buildToken: 'oxemtoken'
    },
    headers
});

export const axiosClient = axios.create({
    baseURL: VERCEL_URL ? `https://${VERCEL_URL}/proxy/api` : PROXY_BASE_API_URL,
    headers
});

export const axiosForm = axios.create({
    baseURL: VERCEL_URL ? `https://${VERCEL_URL}/proxy/form` : PROXY_FORM_API_URL,
});
