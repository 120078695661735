import { forwardRef } from 'react';
import styles from './Button.module.css';

const Button = forwardRef(({ className = '', children, onClick }, ref) => {
  return (
    <button onClick={onClick} className={`${styles.root} ${className}`}>
      <span className={styles.root__inner}>{children}</span>
    </button>
  );
});

export { Button };
