import { useWindowWidth } from '../hooks/useWindowWidth'
import { Footer } from './Footer'
import { Header } from './Header'
import { SubHeader } from './SubHeader'
import { useState } from 'react'
import styles from './Layout.module.css'
import {useRouter} from "next/router";
const Layout = ({ children, globalSettings }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const windowWidth = useWindowWidth()
  const router = useRouter()
  return (
    <div className={styles.wrapper}>
      {windowWidth && windowWidth >= 991 && <SubHeader globalSettings={globalSettings}/>}
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main id={"main"} style={{ overflow: 'hidden', borderTop: router.asPath == "/tech-documentation" ? "1px solid #F0F0F0" : "" }}>{children}</main>
      <Footer globalSettings={globalSettings}/>
      {isMenuOpen && <div className="overlay" />}
    </div>
  )
}

export default Layout
