import Popup from 'reactjs-popup'
import CloseIcon from "../CloseIcon/CloseIcon";
import styles from './SuccessModal.module.css'

const SuccessModal = ({ isFormSubmitted, setIsFormSubmitted }) => {
  return (
    <Popup
      open={isFormSubmitted}
      modal
      lockScroll
      className="modal"
      onClose={() => setIsFormSubmitted(false)}
    >
      {(close) => (
        <div className={styles.modal}>
          <CloseIcon onClick={close} className={styles.close} />
          <div className={styles.success}>
            <h2 className={`${styles.title} ${styles.title_submitted}`}>
              Ваша заявка отправлена
            </h2>
            <p
              className={`${styles.description} ${styles.description_submitted}`}
            >
              В ближайшее время мы обработаем вашу заявку и свяжемся с вами
            </p>
          </div>
        </div>
      )}
    </Popup>
  )
}

export { SuccessModal }
