import { Logo } from '../../components/Logo'
import { Wrapper } from '../../components/Wrapper'
import styles from './Footer.module.css'
import { Button } from '../../components/Button'
import { ModalCallback } from '../../components/ModalCallback'
import { useState } from 'react'
import Inst from './inst.svg';
import VK from './vk.svg';
import Link from "next/link";
import {useGetCategoriesQuery} from "../../store/api/categoriesApi";

const LINKS = {
  catalog: [
    {
      title: 'Кабельный канал',
      link: '/catalog',
    },
    {
      title: 'Структурированные кабельные системы',
      link: '/catalog',
    },
    {
      title: 'Электроустановочные изделия',
      link: '/catalog',
    },
    {
      title: 'Розеточные установки',
      link: '/catalog',
    },
    {
      title: 'Коробки для электромонтажа',
      link: '/catalog',
    },
    {
      title: 'Трубы гофрированные и гладкие жесткие',
      link: '/catalog',
    },
  ],
  map: [
    {
      title: 'О компании',
      link: '/company',
    },

    {
      title: 'Каталог продукции',
      link: '/catalog',
    },
    {
      title: 'Дистрибьюторы',
      link: '/distributor',
    },
    {
      title: 'Контакты',
      link: '/contacts',
    },

    {
      title: 'Техническая документация',
      link: '/tech-documentation',
    },
  ],
}

const Footer = ({globalSettings}) => {
  const date = new Date()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {data: categories} = useGetCategoriesQuery()
  return (
    <footer className={styles.root}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.col}>
          <Link className={styles.logo} href="/">
            <Logo color="white" />
          </Link>
          <div className={styles.icons}>
            <Link href={'https://vk.com/spl__group'} target={'_blank'}><VK/></Link>
            <Link href={'https://www.instagram.com/spl__group/'} target={'_blank'}><Inst/></Link>
          </div>
        </div>
        <div className={styles.col}>
          <h2 className={styles.title}>Каталог продукции</h2>

          <div className={styles.links}>
            {categories && categories.data.map(({ slug, title }, index) => {
              return (
                <Link className={`link ${styles.link}`} key={index} href={'/products/' + slug}>
                  {title}
                </Link>
              )
            })}
          </div>
        </div>

        <div className={styles.col}>
          <h2 className={styles.title}>Карта сайта</h2>

          <div className={styles.links}>
            {LINKS.map.map(({ title, link }, index) => {
              return (
                <Link className={`link ${styles.link}`} key={index} href={link}>
                  {title}
                </Link>
              )
            })}
          </div>
        </div>

        <div className={styles.col}>
          <h2 className={styles.title}>Контакты</h2>

          <Link target={'_blank'} className={`link ${styles.info__link}`} href={`tel:${globalSettings.phone}`}>
            {globalSettings.phone}
          </Link>
          <Link
            className={`link ${styles.info__link}`}
            target={'_blank'}
            href={`mailto:${globalSettings.email}`}
          >
            {globalSettings.email}
          </Link>
          <Link
            className={`link ${styles.info__link}`}
            target={'_blank'}
            href="https://yandex.ru/maps/-/CCUZySxocA"
          >
            {globalSettings.address}
          </Link>

          <Button
            className={styles.callback}
            onClick={() => setIsModalOpen(true)}
          >
            Cвязаться
          </Button>
          {isModalOpen && (
            <ModalCallback
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            />
          )}
        </div>
      </Wrapper>
      <Wrapper className={styles.wrapper}>
        <div className={styles.copy__wrapper}>
          <span className={styles.copy}>
           {globalSettings.copyright}, © {date.getFullYear()}
          </span>
          <Link className={`link ${styles.policy}`} href="/">
            Политика конфиденциальности
          </Link>
        </div>
        <Link
          className={styles.copy__link}
          href="https://inpro.digital/"
          target="_blank"
          rel="noreferrer"
        >
        </Link>
      </Wrapper>
    </footer>
  )
}

export { Footer }
