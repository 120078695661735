import styles from './InputUnderline.module.css'

const InputUnderline = ({
  name,
  fieldName,
  pattern,
  register,
  errors,
  errorText,
  required,
  light,
  className = '',
}) => {
  return (
    <label
      className={`${styles.label} ${light ? styles.label_light : ''} ${
        className ? styles.label_hidden : ''
      } ${className}`}
    >
      <input
        required={required}
        placeholder=" "
        className={`${styles.input} ${light ? styles.input_light : ''}`}
        {...register(fieldName, {
          required: 'Это поле необходимо заполнить',
          pattern: {
            value: pattern,
            message: errorText,
          },
        })}
      />
      <span className={`${styles.name} ${light ? styles.name_light : ''}`}>
        {name}
      </span>

      {errors && errors[fieldName] && errors[fieldName].type === 'required' && (
        <div className={`${styles.error} ${light ? styles.error_light : ''}`}>
          {errors[fieldName].message}
        </div>
      )}
      {errors && errors[fieldName] && errors[fieldName].type === 'pattern' && (
        <div className={`${styles.error} ${light ? styles.error_light : ''}`}>
          {errors[fieldName].message}
        </div>
      )}
    </label>
  )
}

export { InputUnderline }
