import styles from './ModalCallback.module.css'
import Popup from 'reactjs-popup'
import { useState, useEffect } from 'react'
import { InputUnderline } from '../InputUnderline'
import { Button } from '../Button'
import { useForm } from 'react-hook-form'
import { Checkbox } from '../Checkbox'
import { SuccessModal } from '../SuccessModal'
import CloseIcon from "../CloseIcon/CloseIcon";
import Input from "../Input/Input";
import { axiosForm } from "../../axios/axiosCustom";
import { API } from "../../api/api";

const ModalCallback = ({ isOpen, onClose }) => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [pageTitle, setPageTitle] = useState('')

    useEffect(() => {
        if (isOpen) {
            const currentTitle = document.title
            setPageTitle(currentTitle)
        }
    }, [isOpen])

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange',
    })

    const onSubmit = async (data) => {
        const dataToSend = {
            "fields": {
                "TITLE": `Новая заявка со страницы: ${pageTitle}`,
                "NAME": data.name,
                "EMAIL": [{
                    "VALUE": data.email,
                    "VALUE_TYPE": "WORK"
                }],
                "PHONE": [{
                    "VALUE": data.phone,
                    "VALUE_TYPE": "WORK"
                }],
            }
        }
        const fetcher = await axiosForm({
            url: API.getForm,
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify(dataToSend),
        })

        if (fetcher.status === 200) setIsFormSubmitted(true)
    }

    return (
        <Popup
            open={isOpen}
            modal
            lockScroll
            className="modal"
            onClose={() => {
                setIsFormSubmitted(false)
                onClose()
            }}
        >
            {(close) => (
                <div className={styles.modal}>
                    <CloseIcon onClick={close} className={styles.close} />
                    <div>
                        <h2 className={styles.title}>Оставить заявку</h2>
                        <p className={styles.description}>
                            Оставьте ваши контактные данные и наши специалисты оперативно
                            проконсультируют вас по интересующему вопросу
                        </p>
                        <form
                            className={styles.form}
                            noValidate
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div
                                className={`${styles.form_upper} ${
                                    errors && errors['email'] ? styles.form_error : ''
                                }`}
                            >
                                <InputUnderline
                                    type="text"
                                    name="Имя"
                                    fieldName="name"
                                    required
                                    register={register}
                                    errors={errors}
                                    errorText="Введите корректное имя"
                                    pattern={/^.{1,120}$/}
                                    light
                                />
                                <Input
                                    mask="+7 (999) 999 99 99"
                                    type="text"
                                    name="Телефон"
                                    fieldName="phone"
                                    required
                                    register={register}
                                    errors={errors}
                                    errorText="Введите корректный телефон"
                                    pattern={
                                        /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/
                                    }
                                    light
                                />
                                <InputUnderline
                                    type="text"
                                    name="E-mail"
                                    fieldName="email"
                                    required
                                    register={register}
                                    errors={errors}
                                    errorText="Введите корректный email"
                                    pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i}
                                    light
                                />
                                <Button className={`${styles.button}`} type="submit">
                                    Отправить
                                </Button>
                                {Object.keys(errors).length === 0 && isFormSubmitted && (
                                    <SuccessModal
                                        isFormSubmitted={isFormSubmitted}
                                        setIsFormSubmitted={setIsFormSubmitted}
                                    />
                                )}
                            </div>
                            <div className={`${styles.form_lower}`}>
                                <Checkbox
                                    name="agreement"
                                    required
                                    register={register}
                                    errors={errors}
                                    light
                                >
                                    Я согласен на обработку моих персональных данных
                                </Checkbox>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </Popup>
    )
}

const ParentComponent = () => {
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div>
            <button onClick={openModal}>Открыть форму</button>
            {isModalOpen && (
                <ModalCallback
                    isOpen={isModalOpen}
                    onClose={closeModal}
                />
            )}
        </div>
    )
}

export { ModalCallback, ParentComponent }
