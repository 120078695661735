import styles from './Input.module.scss'
import {DetailedHTMLProps, InputHTMLAttributes} from "react";
import InputMask from "react-input-mask";


interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>{
    name: string,
    fieldName: string,
    pattern: any,
    register: any,
    errors: any,
    errorText: string,
    light?: boolean,
    mask?: string
}


const Input = ({
        mask,
        name,
        fieldName,
        pattern,
        register,
        errors,
        errorText,
        required,
        light,
        className,
    }: InputProps) => {
    return (
        <label
            className={`${styles.label} ${light ? styles.label_light : ''} ${
                className ? styles.label_hidden : ''
            } ${className}`}
        >
            {mask ? <InputMask
                mask={mask}
                required={required}
                placeholder=" "
                className={`${styles.input} ${light ? styles.input_light : ''}`}
                {...register(fieldName, {
                    required: 'Это поле необходимо заполнить',
                    pattern: {
                        value: pattern,
                        message: errorText,
                    },
                })}
            /> :<input
                    required={required}
                    placeholder=" "
                    className={`${styles.input} ${light ? styles.input_light : ''}`}
                    {...register(fieldName, {
                        required: 'Это поле необходимо заполнить',
                        pattern: {
                        value: pattern,
                        message: errorText,
                    },
                    })}
                />
            }
            <span className={`${styles.name} ${light ? styles.name_light : ''}`}>
        {name}
      </span>

            {errors && errors[fieldName] && errors[fieldName].type === 'required' && (
                <div className={`${styles.error} ${light ? styles.error_light : ''}`}>
                    {errors[fieldName].message}
                </div>
            )}
            {errors && errors[fieldName] && errors[fieldName].type === 'pattern' && (
                <div className={`${styles.error} ${light ? styles.error_light : ''}`}>
                    {errors[fieldName].message}
                </div>
            )}
        </label>
    )
}

export default Input
