import { Wrapper } from '../../components/Wrapper'
import styles from './SubHeader.module.css'
import Link from "next/link";
import cn from "classnames";

const SubHeader = ({globalSettings}) => {
  return (
    <div className={styles.subheader}>
      <Wrapper className={styles.root}>
        <span className={styles.text}>
          {globalSettings.workingHours}
        </span>
        <div className={styles.links}>
          <Link className={`link ${styles.link}`} href={`mailto:${globalSettings.email}`}>
            <img
              className={styles.icon}
              src="/images/mail.svg"
              alt="Mail Icon"
            />{' '}
            {globalSettings.email}
          </Link>
          <Link className={`link ${cn(styles.linkNum, styles.link)}`} href={`tel:${globalSettings.phone}`}>
            <img
              className={styles.icon}
              src="/images/phone.svg"
              alt="Phone Icon"
            />{' '}
            {globalSettings.phone}
          </Link>
        </div>
      </Wrapper>
    </div>
  )
}

export { SubHeader }
