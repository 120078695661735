import { Button } from '../../components/Button'
import { Logo } from '../../components/Logo'
import { Wrapper } from '../../components/Wrapper'
import styles from './Header.module.css'
import { useWindowWidth } from '../../hooks/useWindowWidth'
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll'
import {useEffect, useState} from 'react'
import { ModalCallback } from '../../components/ModalCallback'
import Link from "next/link";
import SearchIcon from './search.svg'
import Search from "../../components/Search/Search";
import cn from "classnames";
import {useGetCategoriesQuery} from "../../store/api/categoriesApi";

const MENU_ITEMS = [
  {
    title: 'Кабельный канал',
    image: '/images/catalog/card-1.svg',
    link: '/products/cable-canal',
  },
  {
    title: 'Структурированные кабельные системы (СКС)',
    image: '/images/catalog/card-3.svg',
    link: '/products/ckc',
  },
  {
    title: 'Блоки розеточные',
    image: '/images/catalog/card-5.svg',
    link: '/products/block-rozetochniy',
  },
  {
    title: 'Электроустановочные изделия',
    image: '/images/catalog/card-2.svg',
    link: '/products/electro',
  },
  {
    title: 'Трубы гофрированные и&nbsp;гладкие жесткие',
    image: '/images/catalog/card-4.svg',
    link: '/products/truby-jestkie',
  },
  {
    title: 'Электромонтажные коробки',
    image: '/images/catalog/card-6.svg',
    link: 'products/korob-montaj',
  },
]

const BurgerIcon = ({ className = '', onClick }) => {
  return (
    <div onClick={onClick} className={`${styles.burger} ${className}`}>
      <div className={styles.burger__inner} />
      <div className={styles.burger__inner} />
      <div className={styles.burger__inner} />
    </div>
  )
}

const MenuItem = ({ link, text, className = '' }) => {
  return (
    <Link className={`link ${styles.link} ${className}`} href={link}>
      <span className={styles.link__text}>{text}</span>
    </Link>
  )
}

const MenuMobile = () => {
  const [isProductsVisible, setIsProductsVisible] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  useLockBodyScroll()
  const {data} = useGetCategoriesQuery()
  const sortedArray = data?.data.length ? [...data.data].sort((a, b) => a.id == 3 ? -1 : b.id == 3 ? 1 : 0) : [];
  return (
    <nav
      className={`${styles.menu} ${styles.menu_mob} ${
        isProductsVisible ? styles.menu_mob_hidden : ''
      }`}
    >
      <Link
        href={'/catalog'}
        className={styles.button__link}
        onClick={() => setIsProductsVisible(true)}
      >
        <BurgerIcon /> <span>Каталог продукции</span>
      </Link>
      {isProductsVisible && (
        <div className={styles.products}>
          <div className={styles.products__header}>
            <button
              className={styles.button_back}
              onClick={() => setIsProductsVisible(false)}
            >
              <img src="/images/back.svg" alt="Back icon" />
            </button>

            <div className={styles.products__title}>
              <span>Каталог продукции</span>
            </div>
          </div>
          <div className={styles.categories}>
            {sortedArray.map(({ slug, title }, index) => {
              return (
                <Link
                  key={index}
                  href={`/products/${slug}`}
                  className={`link ${styles.category}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: title }} />
                  <img
                    className={styles.category__arrow}
                    src="/images/back.svg"
                    alt="Back icon"
                  />
                </Link>
              )
            })}
          </div>
        </div>
      )}
      <div className={styles.menu__links}>
        <MenuItem link="/company" text="О компании" />
        <MenuItem link="/distributor" text="Дистрибьюторы" />
        <MenuItem link="/contacts" text="Контакты" />
        <MenuItem link="/news" text="Новости" />
        <MenuItem link="/sks" text="СКС" />
        <MenuItem link="/tech-documentation" text="Техническая документация" />
      </div>
      <div className={styles.contacts}>
        <Link href="tel:78043337595" className={styles.contact}>
          +7 (804) 333-75-95
        </Link>
        <Link href="mailto:mail@spl.group" className={styles.contact}>
          mail@spl.group
        </Link>
        <p className={styles.contacts__text}>
          Время работы ПН–ВС с 9:00 до 20:00 по МСК
        </p>
        <Button
          onClick={() => setIsModalOpen(true)}
          className={styles.callback}
        >
          Обратный звонок
        </Button>
        {isModalOpen && (
          <ModalCallback
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </div>
    </nav>
  )
}

const HeaderMobile = ({ openSearch, isSearch, setOpenSearch, isMenuOpen, setIsMenuOpen }) => {
  return (
    <Wrapper className={`${styles.wrapper} ${styles.wrapper_mob}`}>
      <Link className={`link ${styles.phone}`} href="tel:78043337595">
        <img className={styles.icon} src="/images/phone.svg" alt="Phone Icon" />
      </Link>

      <Link className={styles.logo__wrapper} href="/">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.right}>
      <div onClick={() => setOpenSearch(true)} className={styles.searchWrapper}>
        {openSearch && <div className={styles.over}></div>}
        {isSearch && <SearchIcon className={cn(styles.search, openSearch && styles.activeSearch)}/>}
        {openSearch &&
          <svg className={styles.xmark} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="17" height="17" rx="8.5" fill="#F0F0F0"/>
            <path d="M11.6666 5.33469L5.33594 11.6653M5.33594 5.33469L11.6666 11.6653" stroke="black" stroke-width="1.19782" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        }
      </div>
      <BurgerIcon
        className={`${styles.burger__button} ${
          isMenuOpen ? styles.burger_active : ''
        }`}
        onClick={() => setIsMenuOpen((state) => !state)}
      />
      </div>
      {isMenuOpen && <MenuMobile />}
    </Wrapper>
  )
}

const HeaderDesktop = ({ isMenuOpen, isSearch, setIsMenuOpen, openSearch, setOpenSearch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const {data} = useGetCategoriesQuery()
  const sortedArray = data?.data.length ? [...data.data].sort((a, b) => a.id == 3 ? -1 : b.id == 3 ? 1 : 0) : [];
  return (
  <>
    <Wrapper className={styles.wrapper}>

      <Link className={styles.logo__wrapper} href="/">
        <Logo className={styles.logo} />
      </Link>
      <div className={styles.nav}>
        <nav
            className={`${styles.menu} ${styles.menu_desk} ${
                isMenuOpen ? styles.open : ''
            }`}
        >
          <div className={styles.button__link_wrapper}>
            <Link
                className={`link ${styles.button__link}`}
                href="/catalog"
                onMouseEnter={() => setIsMenuOpen(true)}
                onMouseLeave={() => setIsMenuOpen(false)}
                onTouchStart={() => setIsMenuOpen(true)}
            >
              <BurgerIcon/> <span>Каталог продукции</span>
            </Link>
            {isMenuOpen && !openSearch && (
                <div
                    className={styles.menu_open}
                    onMouseEnter={() => setIsMenuOpen(true)}
                    onMouseLeave={() => setIsMenuOpen(false)}
                >
                  {sortedArray.map(({slug, title, image}, index) => {
                    return (
                        <Link
                            key={index}
                            href={`/products/${slug}`}
                            className={`link ${styles.menu__item}`}
                        >
                          <img
                              className={styles.menu__item_img}
                              src={image}
                              alt="Icon"
                          />
                          <div>
                            <span dangerouslySetInnerHTML={{__html: title}}/>
                          </div>
                        </Link>
                    )
                  })}
                </div>
            )}
          </div>
          <MenuItem link="/company" text="О компании"/>
          <MenuItem link="/distributor" text="Дистрибьюторы"/>
          <MenuItem link="/contacts" text="Контакты"/>
          <MenuItem link="/news" text="Новости" />
          <MenuItem link="/sks" text="CКС"/>
          <MenuItem link="/tech-documentation" text="Техническая документация"/>
        </nav>

        <div className={styles.callSearch}>
          <div onClick={() => setOpenSearch(true)} className={styles.searchWrapper}>
          {openSearch && <div className={styles.over}></div>}
          {isSearch && <SearchIcon className={cn(styles.search, openSearch && styles.activeSearch)}/>}
          {openSearch &&
            <svg className={styles.xmark} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="17" height="17" rx="8.5" fill="#F0F0F0"/>
              <path d="M11.6666 5.33469L5.33594 11.6653M5.33594 5.33469L11.6666 11.6653" stroke="black" stroke-width="1.19782" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          }
        </div>
        <Button onClick={() => setIsModalOpen(true)} className={styles.callback}>
          Обратный звонок
        </Button>
      </div>
      </div>
      {isModalOpen && (
        <ModalCallback
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </Wrapper>
  </>
  )
}

const Header = ({ isMenuOpen, setIsMenuOpen }) => {
  const windowWidth = useWindowWidth()
  const [openSearch, setOpenSearch] = useState(false)
  const isSearch = true
  return (
    <>
      <header
        className={`${styles.root} ${isMenuOpen ? styles.root_bordered : ''}`}
      >
        <div className={styles.wrapperHeaders}>
        {windowWidth && windowWidth >= 991 ? (
          <HeaderDesktop isSearch={isSearch} setOpenSearch={setOpenSearch} openSearch={openSearch} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        ) : (
          <HeaderMobile isSearch={isSearch} setOpenSearch={setOpenSearch} openSearch={openSearch}  isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
        )}
        </div>
        {isSearch ? (openSearch && <Search setOpen={setOpenSearch} open={openSearch}/>) : <></>}
      </header>
    </>
  )
}

export { Header }
