import React from 'react';

const Logo = ({ color = 'black', className = '' }) => {
  const currentColor = color === 'black' ? '#171717' : '#FFFFFF'

  return (
    <svg
      className={className}
      width="208"
      height="36"
      viewBox="0 0 208 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64862 16.1333C6.64853 17.4152 7.03137 18.5415 8.12102 19.4703C9.1128 20.3158 11.0121 20.6267 11.8183 20.6578C12.9808 20.7027 14.6179 20.4486 16.3372 20.1819C19.7338 19.6548 23.451 19.078 24.4627 20.6578C25.9254 22.9417 19.2564 30.4864 11.0647 34.5483C4.64602 31.8864 0.131348 25.5595 0.131348 18.178C0.131348 8.50952 7.87705 0.65014 17.5017 0.46666C8.88337 7.50917 6.64862 12.928 6.64862 16.1333ZM27.6957 3.45198C18.0317 6.68224 10.5802 13.4762 11.8183 15.2959C12.6007 16.4457 14.5027 16.0155 16.6872 15.5215C17.0213 15.446 17.3619 15.369 17.7061 15.2959C20.3017 14.7447 25.5179 13.7463 28.4006 17.0511C29.4663 18.2729 29.7484 19.8773 29.6574 21.3651C29.5625 22.9203 29.0477 24.6919 28.0893 26.6083C27.469 27.8487 25.606 31.4012 21.4125 35.5335C29.4865 33.8831 35.5606 26.7399 35.5606 18.178C35.5606 12.0397 32.4385 6.63058 27.6957 3.45198Z"
        fill="url(#paint0_linear_12_1813)"
      />
      <path
        d="M45.3146 23.5791C46.5973 23.9426 47.9967 24.2333 49.5127 24.4514C51.0578 24.6453 52.5884 24.7422 54.1044 24.7422C56.1743 24.7422 57.7049 24.6695 58.6961 24.5241C59.7165 24.3545 60.387 24.1001 60.7077 23.7608C61.0284 23.4216 61.1887 22.9855 61.1887 22.4524C61.1887 21.992 61.0721 21.6285 60.8389 21.362C60.6057 21.0954 60.1538 20.8895 59.4832 20.7441C58.8127 20.5745 57.8215 20.4412 56.5096 20.3443L53.6234 20.1262C51.466 19.9566 49.7605 19.581 48.5069 18.9995C47.2824 18.4179 46.4078 17.6426 45.8831 16.6733C45.3875 15.6799 45.1396 14.4926 45.1396 13.1114C45.1396 11.4637 45.5332 10.131 46.3204 9.11332C47.1367 8.09563 48.4486 7.35659 50.2561 6.8962C52.0636 6.41159 54.4688 6.16928 57.4716 6.16928C61.3782 6.16928 64.5997 6.59332 67.1361 7.4414L67.0049 12.3845L66.8737 12.5299C63.9875 11.6818 60.9701 11.2577 57.8215 11.2577C56.3055 11.2577 55.1393 11.3304 54.323 11.4758C53.5067 11.597 52.9382 11.8029 52.6176 12.0937C52.2969 12.3845 52.1365 12.7964 52.1365 13.3295C52.1365 13.8626 52.2386 14.2624 52.4426 14.5289C52.6759 14.7954 53.1277 15.0014 53.7983 15.1468C54.4688 15.2679 55.4455 15.3891 56.7282 15.5103L59.4832 15.7647C61.8155 15.9828 63.6085 16.3704 64.8621 16.9278C66.1157 17.4851 66.9757 18.2362 67.4422 19.1812C67.9378 20.102 68.1856 21.2287 68.1856 22.5614C68.1856 24.1849 67.7629 25.5418 66.9174 26.6322C66.1011 27.6983 64.76 28.498 62.8942 29.031C61.0284 29.5641 58.5357 29.8306 55.4163 29.8306C53.3755 29.8306 51.5389 29.7458 49.9063 29.5762C48.2737 29.3824 46.6848 29.128 45.1396 28.813V23.6881L45.3146 23.5791Z"
        fill={currentColor}
      />
      <path
        d="M79.574 29.5035H72.5771V6.4964H84.9091C87.6787 6.4964 89.8943 6.77505 91.5561 7.33236C93.2179 7.88967 94.4132 8.79832 95.142 10.0583C95.8708 11.3183 96.2353 13.0266 96.2353 15.1831C96.2353 17.2185 95.8708 18.8783 95.142 20.1626C94.4132 21.4226 93.2179 22.3555 91.5561 22.9612C89.8943 23.567 87.6787 23.8699 84.9091 23.8699H79.574V29.5035ZM83.7721 11.2577H79.574V19.1085H83.7721C85.842 19.1085 87.2268 18.8178 87.9265 18.2362C88.6262 17.6547 88.976 16.637 88.976 15.1831C88.976 13.802 88.6262 12.8085 87.9265 12.2027C87.2268 11.5727 85.842 11.2577 83.7721 11.2577Z"
        fill={currentColor}
      />
      <path
        d="M120.067 24.5605V29.5035H99.9513V6.4964H106.948V24.5605H120.067Z"
        fill={currentColor}
      />
      <path
        d="M133.289 10.1493H133.75L136.331 6.6339H138.637L135.458 10.9097L138.924 15.4847H136.643L133.937 12.0067H133.289V15.4847H131.245V6.6339H133.289V10.1493Z"
        fill="#13AD37"
      />
      <path
        d="M141.965 6.6339H143.809L147.038 15.4847H144.944L144.258 13.5026H141.503L140.83 15.4847H138.736L141.965 6.6339ZM143.922 12.094L142.887 8.96503L141.827 12.094H143.922Z"
        fill="#13AD37"
      />
      <path
        d="M147.947 6.6339H154.567V8.42899H150.004V10.1244H152.148C153.104 10.1244 153.848 10.3571 154.38 10.8225C154.92 11.2795 155.19 11.9278 155.19 12.7671C155.19 13.6314 154.932 14.3005 154.417 14.7742C153.902 15.2479 153.175 15.4847 152.235 15.4847H147.947V6.6339ZM151.999 13.752C152.356 13.752 152.626 13.6647 152.809 13.4902C153 13.3073 153.096 13.058 153.096 12.7422C153.096 12.4513 153.004 12.2145 152.821 12.0317C152.639 11.8405 152.364 11.7449 151.999 11.7449H150.004V13.752H151.999Z"
        fill="#13AD37"
      />
      <path
        d="M162.568 13.6896V15.4847H156.347V6.6339H162.455V8.42899H158.392V10.1493H161.882V11.8073H158.392V13.6896H162.568Z"
        fill="#13AD37"
      />
      <path
        d="M166.963 9.70052C166.855 10.8225 166.717 11.7616 166.551 12.5178C166.393 13.2658 166.173 13.8683 165.891 14.3254C165.608 14.7742 165.247 15.0941 164.806 15.2853C164.366 15.4764 163.817 15.572 163.161 15.572L163.098 13.7769C163.397 13.7436 163.647 13.673 163.846 13.565C164.054 13.4486 164.228 13.2325 164.37 12.9167C164.511 12.5926 164.627 12.1355 164.719 11.5455C164.819 10.9554 164.914 10.1659 165.006 9.17695L165.23 6.6339H170.977V15.4847H168.932V8.42899H167.087L166.963 9.70052Z"
        fill="#13AD37"
      />
      <path
        d="M172.745 6.6339H174.802V9.66312H176.373C176.846 9.66312 177.274 9.72961 177.657 9.86258C178.047 9.98724 178.38 10.1742 178.654 10.4235C178.928 10.6646 179.14 10.9637 179.29 11.3211C179.439 11.6784 179.514 12.0815 179.514 12.5303C179.514 12.9957 179.439 13.4112 179.29 13.7769C179.149 14.1426 178.941 14.4542 178.667 14.7118C178.401 14.9611 178.081 15.1523 177.707 15.2853C177.333 15.4182 176.917 15.4847 176.46 15.4847H172.745V6.6339ZM174.802 13.752H176.211C176.618 13.752 176.921 13.6439 177.121 13.4278C177.329 13.2035 177.432 12.9001 177.432 12.5178C177.432 12.1688 177.329 11.8779 177.121 11.6452C176.913 11.4042 176.61 11.2837 176.211 11.2837H174.802V13.752Z"
        fill="#13AD37"
      />
      <path
        d="M188.287 6.6339V15.4847H186.243V11.8571H182.715V15.4847H180.67V6.6339H182.715V10.062H186.243V6.6339H188.287Z"
        fill="#13AD37"
      />
      <path
        d="M190.068 6.6339H192.125V9.66312H193.696C194.17 9.66312 194.598 9.72961 194.98 9.86258C195.371 9.98724 195.703 10.1742 195.977 10.4235C196.252 10.6646 196.464 10.9637 196.613 11.3211C196.763 11.6784 196.837 12.0815 196.837 12.5303C196.837 12.9957 196.763 13.4112 196.613 13.7769C196.472 14.1426 196.264 14.4542 195.99 14.7118C195.724 14.9611 195.404 15.1523 195.03 15.2853C194.656 15.4182 194.24 15.4847 193.783 15.4847H190.068V6.6339ZM197.947 15.4847V6.6339H199.991V15.4847H197.947ZM192.125 13.752H193.534C193.941 13.752 194.245 13.6439 194.444 13.4278C194.652 13.2035 194.756 12.9001 194.756 12.5178C194.756 12.1688 194.652 11.8779 194.444 11.6452C194.236 11.4042 193.933 11.2837 193.534 11.2837H192.125V13.752Z"
        fill="#13AD37"
      />
      <path
        d="M208 13.6896V15.4847H201.78V6.6339H207.888V8.42899H203.824V10.1493H207.315V11.8073H203.824V13.6896H208Z"
        fill="#13AD37"
      />
      <path
        d="M130.696 24.8939C130.696 24.362 130.796 23.8343 130.995 23.3107C131.195 22.7788 131.486 22.3051 131.868 21.8896C132.25 21.4741 132.716 21.1375 133.264 20.8799C133.813 20.6222 134.436 20.4934 135.134 20.4934C135.965 20.4934 136.684 20.6721 137.291 21.0294C137.906 21.3868 138.363 21.8522 138.662 22.4256L137.091 23.5226C136.992 23.2899 136.863 23.0988 136.705 22.9492C136.555 22.7913 136.389 22.6666 136.206 22.5752C136.023 22.4755 135.836 22.409 135.645 22.3758C135.454 22.3342 135.267 22.3134 135.084 22.3134C134.694 22.3134 134.353 22.3924 134.062 22.5503C133.771 22.7082 133.53 22.9118 133.339 23.1611C133.148 23.4104 133.007 23.693 132.915 24.0088C132.824 24.3246 132.778 24.6446 132.778 24.9687C132.778 25.3177 132.832 25.6543 132.94 25.9784C133.048 26.3025 133.202 26.5893 133.401 26.8386C133.609 27.0879 133.854 27.2873 134.137 27.4369C134.428 27.5782 134.752 27.6489 135.109 27.6489C135.292 27.6489 135.479 27.6281 135.67 27.5865C135.87 27.5367 136.057 27.466 136.231 27.3746C136.414 27.2749 136.58 27.1502 136.73 27.0006C136.879 26.8427 137 26.6516 137.091 26.4272L138.762 27.412C138.629 27.7361 138.429 28.027 138.163 28.2846C137.906 28.5423 137.607 28.7583 137.266 28.9329C136.925 29.1074 136.564 29.2403 136.181 29.3318C135.799 29.4232 135.425 29.4689 135.059 29.4689C134.419 29.4689 133.829 29.3401 133.289 29.0824C132.757 28.8165 132.296 28.4675 131.905 28.0353C131.523 27.6032 131.224 27.1128 131.008 26.5643C130.8 26.0158 130.696 25.459 130.696 24.8939Z"
        fill="#13AD37"
      />
      <path
        d="M141.884 26.2153L146.048 20.5433H147.569V29.3941H145.524V23.8592L141.41 29.3941H139.827V20.5433H141.884V26.2153Z"
        fill="#13AD37"
      />
      <path
        d="M148.787 24.8939C148.787 24.362 148.886 23.8343 149.086 23.3107C149.285 22.7788 149.576 22.3051 149.958 21.8896C150.341 21.4741 150.806 21.1375 151.355 20.8799C151.903 20.6222 152.526 20.4934 153.224 20.4934C154.055 20.4934 154.774 20.6721 155.381 21.0294C155.996 21.3868 156.453 21.8522 156.752 22.4256L155.182 23.5226C155.082 23.2899 154.953 23.0988 154.795 22.9492C154.646 22.7913 154.479 22.6666 154.296 22.5752C154.114 22.4755 153.927 22.409 153.736 22.3758C153.544 22.3342 153.357 22.3134 153.175 22.3134C152.784 22.3134 152.443 22.3924 152.152 22.5503C151.861 22.7082 151.62 22.9118 151.429 23.1611C151.238 23.4104 151.097 23.693 151.005 24.0088C150.914 24.3246 150.868 24.6446 150.868 24.9687C150.868 25.3177 150.922 25.6543 151.03 25.9784C151.138 26.3025 151.292 26.5893 151.492 26.8386C151.699 27.0879 151.945 27.2873 152.227 27.4369C152.518 27.5782 152.842 27.6489 153.199 27.6489C153.382 27.6489 153.569 27.6281 153.76 27.5865C153.96 27.5367 154.147 27.466 154.321 27.3746C154.504 27.2749 154.67 27.1502 154.82 27.0006C154.97 26.8427 155.09 26.6516 155.182 26.4272L156.852 27.412C156.719 27.7361 156.52 28.027 156.254 28.2846C155.996 28.5423 155.697 28.7583 155.356 28.9329C155.015 29.1074 154.654 29.2403 154.272 29.3318C153.889 29.4232 153.515 29.4689 153.15 29.4689C152.51 29.4689 151.92 29.3401 151.379 29.0824C150.848 28.8165 150.386 28.4675 149.996 28.0353C149.613 27.6032 149.314 27.1128 149.098 26.5643C148.89 26.0158 148.787 25.459 148.787 24.8939Z"
        fill="#13AD37"
      />
      <path
        d="M164.539 22.3384H161.846V29.3941H159.802V22.3384H157.097V20.5433H164.539V22.3384Z"
        fill="#13AD37"
      />
      <path
        d="M171.795 27.599V29.3941H165.575V20.5433H171.683V22.3384H167.619V24.0587H171.11V25.7166H167.619V27.599H171.795Z"
        fill="#13AD37"
      />
      <path
        d="M180.055 29.3941V24.0961L178.135 27.948H177.038L175.118 24.0961V29.3941H173.074V20.5433H175.293L177.587 25.1681L179.893 20.5433H182.099V29.3941H180.055Z"
        fill="#13AD37"
      />
      <path
        d="M183.884 20.5433H185.941V23.5725H187.512C187.986 23.5725 188.414 23.639 188.796 23.7719C189.186 23.8966 189.519 24.0836 189.793 24.3329C190.067 24.5739 190.279 24.8731 190.429 25.2305C190.578 25.5878 190.653 25.9909 190.653 26.4397C190.653 26.9051 190.578 27.3206 190.429 27.6863C190.288 28.0519 190.08 28.3636 189.806 28.6212C189.54 28.8705 189.22 29.0617 188.846 29.1946C188.472 29.3276 188.056 29.3941 187.599 29.3941H183.884V20.5433ZM191.763 29.3941V20.5433H193.807V29.3941H191.763ZM185.941 27.6613H187.35C187.757 27.6613 188.06 27.5533 188.26 27.3372C188.468 27.1128 188.571 26.8095 188.571 26.4272C188.571 26.0781 188.468 25.7873 188.26 25.5546C188.052 25.3136 187.749 25.1931 187.35 25.1931H185.941V27.6613Z"
        fill="#13AD37"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12_1813"
          x1="29.4173"
          y1="4.25285"
          x2="8.19246"
          y2="2.22581"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#13AD37" />
          <stop offset="1" stopColor="#5DAD13" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export {Logo}
