import React, {ButtonHTMLAttributes, DetailedHTMLProps, ReactNode} from 'react';
import cn from "classnames";
import styles from './DefaultButton.module.scss'

interface TitleUnderlineProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>{
    children: ReactNode,
    sidePadding?: boolean,
    fill?: boolean
}

const DefaultButton = ({children, color='green', fill=true, className, onClick, ...props}: TitleUnderlineProps) => {
    return (
        <button onClick={onClick} className={cn(styles.root, className, {
            [styles.fill]: !fill,
        })}>
            <span className={styles.root__inner}>{children}</span>
        </button>
    );
};

export default DefaultButton;