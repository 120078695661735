import styles from './Search.module.scss'
import React, {useEffect, useRef, useState} from 'react';
import Hr from "../Hr/Hr";
import XMArkIcon from 'public/icons/xmark.svg'
import SearchIcon from 'public/icons/search.svg'
import cn from 'classnames'
import Link from "next/link";
import DefaultButton from "../DefaultButton/DefaultButton";
import {useRouter} from "next/router";
import {useOnclickOutside} from "../../hooks/useOnclickOutside";
import {useGetSearchQuery} from "../../store/api/searchApi";
import {wordEnding} from "../../helpers/wordEnding";


interface SearchProps {
    open: boolean,
    setOpen: (v: boolean) => void
}
const Search = ({open, setOpen}: SearchProps) => {
    const router = useRouter();
    const ref = useRef()

    const [search, setSearch] = useState<string>('')
    const [searchQuery, setSearchQuery] = useState('')
    const {data: searchProducts} = useGetSearchQuery(searchQuery)

    useOnclickOutside(ref,() => {
        if(open) {
            setOpen(false)
        }
    })

    useEffect(() => {
        const timeoutSearch = setTimeout(() => {
            if(search.length > 1) {
                setSearchQuery(search)
            }
        }, 400)
        return () => {
            clearTimeout(timeoutSearch)
        }
    }, [search])

    return (
        <div  className={styles.wrapper}>
            <Hr/>
            <div ref={ref} className={styles.root}>
                <div className={styles.container}>
                    <div className={styles.inputWrapper}>
                        <input
                            onKeyDown={(e) => {
                                if(e.key === 'Enter') {
                                    setOpen(false)
                                    router.push('/search/' + search.toLowerCase())
                                }
                            }}
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder={'Поиск'} autoComplete="off"
                            className={styles.input}
                        />
                        <SearchIcon className={cn(styles.search)}/>
                        <XMArkIcon onClick={() => setSearch('')} className={cn(styles.xmark)}/>
                    </div>
                    <div className={styles.autocomplete}>
                        {searchProducts && searchProducts.data.map(e => ({name: e.name, slug: e.slug})).slice(0, 6).map(e =>
                            <div className={styles.hint} onClick={() => setSearch(e.name)} >
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="8.25" cy="8.25" r="4.5" fill="white" stroke="black" stroke-width="1.5"/>
                                    <path d="M15 15L12.75 12.75" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
                                </svg>
                                <Link onClick={() => setOpen(false)} className={styles.hint} href={'/product/' + e.slug}>{e.name}</Link>
                            </div>)}
                    </div>
                    {(searchQuery.length > 1 && searchProducts && searchProducts.data.length ?
                        <DefaultButton
                            onClick={() => {
                                setOpen(false)
                                router.push('/search/' + search.toLowerCase())
                            }}
                            className={styles.button}
                            fill={false}>Показать все {searchProducts.data.length} {wordEnding(searchProducts.data.length, ['результат', 'результатa',' результатов',])}
                        </DefaultButton> : null)}
                </div>
            </div>
        </div>
    );
};

export default Search;